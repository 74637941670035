import styled from "styled-components";

export const Sekcja = styled.section`
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.color.green} 60%,
    ${({ theme }) => theme.color.light} 40%
  );
  color: ${({ theme }) => theme.color.white};
  text-align: center;
`;

export const Ramka = styled.iframe`
  width: 800px;
  height: 400px;
  border: 5px solid ${({ theme }) => theme.color.green};
  border-radius: 18px;
  max-width: 100%;
`;

export const Paragraf = styled.p`
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 40px;
`;
