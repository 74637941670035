import {
  Sekcja,
  Tytul,
  Podtytul,
  Tekst,
  Button,
  Podpis,
  Przycisk,
  Oferta,
  Oferujemy,
  Linia,
  Kontener,
  Ikona,
  Opis,
} from "./styled";
import { ofertapriv } from "./ofertapriv";

const OfertaPrywatna = () => (
  <Sekcja id="kredyty">
    <Przycisk>
      <Button href="#formularz"> Bezpłatna konsultacja </Button>
      <Podpis>
        Zapraszamy do umówienia spotkania, na którym omówimy Państwa sytuację
        finansową.
      </Podpis>
    </Przycisk>
    <Tytul>
      Marzysz o własnym mieszkaniu lub domu, przydałby Ci się nowy samochód...
    </Tytul>
    <Tekst>
      <Podtytul>ale do realizacji tych celów potrzebujesz kredytu?</Podtytul>
      <p>
        A może masz już kredyt i zastanawiasz się, czy nie mógłbyś mieć lepszej
        oferty?
      </p>
      <p>
        Nie musisz już biegać od banku do banku i porównywać ofert. <br />Z nami
        cały proces stanie się prosty i bezstresowy. Zadbamy o każdy szczegół,
        oszczędzając Twój czas i dając Ci pewność, że Twoje finanse są w dobrych
        rękach.
      </p>
    </Tekst>
    <Oferta>
      <Linia />
      <Oferujemy>Oferujemy:</Oferujemy>
      <Linia />
    </Oferta>
    <Kontener>
      {ofertapriv.map(({ imgSrc, tytul }) => (
        <div key={tytul}>
          <Ikona src={imgSrc} alt={tytul} />
          <Opis>{tytul}</Opis>
        </div>
      ))}
    </Kontener>
    <Podtytul>Zaufaj nam, a my zajmiemy się resztą.</Podtytul>
  </Sekcja>
);

export default OfertaPrywatna;
