import styled from "styled-components";

export const Sekcja = styled.section`
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  gap: 10px;
  padding: 0 50px 0 0;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 20px;
    padding: 0;
    justify-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    gap: 10px;
  }
`;

export const Zdjecie = styled.img`
  width: 100%;
  max-width: 600px;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    order: 2; 
`;

export const Naglowek = styled.div`
  display: flex;
  flex-direction: column;
  `;

export const Paragraf = styled.div`
  display: flex;
`;

export const Artykul = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 25px;
`;

export const Podtytul = styled.h4`
  margin-top: 0;
  margin-bottom: 0;
  line-height: 30px;
  font-size: 20px;
`;

export const Numer = styled.div`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.color.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  margin-right: 20px;
`;

export const Tytul = styled.h2`
  margin-top: 25px;
  margin-bottom: 40px;
  margin-left: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding-left: 8px;
  }
`;

export const Opis = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
`;