import styled from "styled-components";
import { Link } from "react-router-dom";

export const Sekcja = styled.footer``;

export const Glowna = styled.div`
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    padding: 20px 8px;
    text-align: center;
    justify-content: center;
  }
`;

export const Dane = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px 50px;
  width: 100%;
  max-width: 600px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    margin-top: 20px;
    align-items: center;
    
    gap: 10px;
  }
`;

export const Icon = styled.img`
  margin: 10px;
`;

export const Hyperlink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    font-size: smaller;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const Stopka = styled.div`
  padding: 5px 50px;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.orange};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    justify-content: center;
  }
`;

export const Linki = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    align-self: center;
  }
`;

export const Tytul = styled.p`
  font-weight: bold;
  margin: 5px 0;
`;

export const Opis = styled.p`
  margin: 0;
`;