import {
  Sekcja,
  Tytul,
  Tekst,
  Oferta,
  Oferujemy,
  Linia,
  Kontener,
  Ikona,
  Opis,
  Komentarz,
  Button,
} from "./styled";
import { ofertafirm } from "./ofertafirm";

const OfertaFirmowa = () => (
  <Sekcja>
    <Tytul>A może szukasz środków na rozwój swojego przedsiębiorstwa? </Tytul>
    <Tekst>
      Nie trać czasu na porównywanie ofert banków. Pozwól nam zająć się analizą
      ofert, dokumentacją i sprawdzaniem zdolności kredytowej. Skorzystaj z
      naszej kompleksowej, profesjonalnej obsługi i skup się na tym, co robisz
      najlepiej - prowadzeniu swojej firmy.
    </Tekst>
    <Oferta>
      <Linia />
      <Oferujemy>Oferujemy:</Oferujemy>
      <Linia />
    </Oferta>
    <Kontener>
      {ofertafirm.map(({ imgSrc, tytul }) => (
        <div key={tytul}>
          <Ikona src={imgSrc} alt={tytul} />
          <Opis>{tytul}</Opis>
        </div>
      ))}
    </Kontener>
    <Komentarz>
      Pozyskaj finansowanie dla swojej firmy z naszą pomocą.
    </Komentarz>
      <Button href="#formularz"> Bezpłatna konsultacja </Button>
  </Sekcja>
);

export default OfertaFirmowa;
