import styled from "styled-components";

export const Sekcja = styled.section`
  background: ${({ theme }) => theme.color.orange};
  margin: 0 auto;
  text-align: center;
`;

export const Kontener = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
`;

export const Strzalka = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
`;

export const Karta = styled.div`
  background: ${({ theme }) => theme.color.light};
  border-radius: 30px;
  margin: 10px;
  padding: 10px;
  width: 350px;
  height: 275px;
  border: 4px solid black;
  max-width: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    width: 350px;
    height: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    height: 290px;
  }
`;

export const Tytul = styled.div`
  background: ${({ theme }) => theme.color.black};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  margin: -10px -10px 10px -10px;
`;

export const Ikona = styled.img`
  width: 25px;
  height: 25px;
`;

export const Autor = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const Gwiazda = styled.img`
  width: 16px;
  height: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    width: 14px;
    height: 14px;
  }
`;

export const Opinia = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-top: 0;
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "black" : "gray")};
  cursor: pointer;
`;

export const Wstep = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
