import styled from "styled-components";
import tlo from "../../../images/tlo.png";

export const Sekcja = styled.section`
  width: 100%;
  height: 100vh;
  padding: 70px 0 0 50px;
  background: url(${tlo});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    background: none;
    height: auto;
    padding: 90px 0 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding: 90px 0 0 0;
    height: auto;
    background-size: 60%;
  }
`;

export const Opis = styled.div`
  max-width: 640px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding: 5px;
  }
`;

export const Wstep = styled.h1`
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 36px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    font-size: 12px;
    margin-bottom: 24px;
  }
`;

export const Tytul = styled.h1`
  margin: 5px 0;
`;

export const Paragraf = styled.p`
  margin-top: 36px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    margin-top: 12px;
  }
`;

export const Przyciski = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Przycisk = styled.a`
  padding: 10px 50px;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.color.orange};
  border: none;
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 33px;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding: 10px 40px;
  }
`;

export const Odnosnik = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export const Strzalka = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
`;

export const Opinie = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Ocena = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 16px;
`;

export const Podstawa = styled.p`
  margin-top: 0;
`;

export const Gwiazda = styled.img`
  width: 28px;
  height: 28px;
`;

export const ZdjecieMobile = styled.img`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    display: block;
    max-width: 100%;
    margin-top: -50px;
  }
`;
