import { useMediaQuery } from "react-responsive";
import {
  Sekcja,
  Zdjecie,
  Paragraf,
  Naglowek,
  Artykul,
  Podtytul,
  Numer,
  Tytul,
  Opis,
} from "./styled";
import sekcja3 from "../../../images/sekcja-3-zdjecie.jpg";
import sekcja3mobile from "../../../images/sekcja-3-zdjecie-mobile.jpg";
import { zysk } from "./zysk";
import { theme } from "../../../theme";

const CoZyskasz = () => {
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint.mobileMax });

  return (
    <Sekcja>
      <Zdjecie src={isMobile? sekcja3mobile : sekcja3} alt="Justyna i Paweł przy komputerze" />
      <div>
        <Tytul>Co zyskasz, współpracując z nami?</Tytul>
        <Artykul>
          {zysk.map(({ numer, tytul, artykul }) => (
            <Paragraf key={numer}>
              <Numer> {numer} </Numer>
              <Naglowek>
                <Podtytul> {tytul} </Podtytul>
                <Opis> {artykul} </Opis>
              </Naglowek>
            </Paragraf>
          ))}
        </Artykul>
      </div>
    </Sekcja>
  );
};

export default CoZyskasz;
