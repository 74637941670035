import { Sekcja, Ramka, Paragraf } from "./styled";

const SpotkajmySie = () => (
  <Sekcja>
    <h3 id="kontakt">Spotkajmy się</h3>
    <Paragraf>
      Nasze biuro znajduje się w Suwałkach, ale obsługujemy klientów z całej
      Polski.
    </Paragraf>
    <Ramka
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2339.15343438701!2d22.93470847643329!3d54.08956457251422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e102397e0af305%3A0xe40107ffae7b0203!2sSerce%20Finans%C3%B3w%20%E2%80%93%20Pawe%C5%82%20Kowalewski%20Po%C5%9Brednik%20kredytowy!5e0!3m2!1spl!2spl!4v1732099365032!5m2!1spl!2spl"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></Ramka>
  </Sekcja>
);

export default SpotkajmySie;
