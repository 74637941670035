import {
  Sekcja,
  Opis,
  Wstep,
  Tytul,
  Paragraf,
  Przyciski,
  Opinie,
  Ocena,
  Podstawa,
  Gwiazda,
  Przycisk,
  Odnosnik,
  Strzalka,
  ZdjecieMobile,
} from "./styled";
import { theme } from "../../../theme";
import { useMediaQuery } from "react-responsive";
import gwiazdka from "../../../images/gwiazdka.png";
import strzalka from "../../../images/icon-arrow.png";
import tlomobile from "../../../images/sekcja-1-zdjecie-mobile.png";

const StronaTytulowa = () => {
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint.tabletMax });

  return (
    <Sekcja>
      <Opis>
        <Wstep>Kredyt hipoteczny Suwałki | Kredyt firmowy</Wstep>
        <Tytul>Kredyt bez stresu. </Tytul>
        <Tytul>Prosta i szybka ścieżka.</Tytul>
         <Paragraf> 
          Uzyskaj dostęp do <strong> najlepszych ofert </strong> kredytowych na
          rynku. <br />
          My zajmiemy się wszystkimi formalnościami za Ciebie, <br />
          <strong> a Ty zaoszczędzisz czas i pieniądze. </strong>
        </Paragraf>

        <Przyciski>
          <Przycisk href="#kredyty">Zobacz ofertę</Przycisk>
          <Odnosnik href="#dlaczego-my">
            Poznajmy się
            <Strzalka src={strzalka} alt="Strzalka"></Strzalka>
          </Odnosnik>
        </Przyciski>
        <Opinie>
          <Ocena>5.0</Ocena>
          <Gwiazda src={gwiazdka} alt="Gwiazdka" />
          <Gwiazda src={gwiazdka} alt="Gwiazdka" />
          <Gwiazda src={gwiazdka} alt="Gwiazdka" />
          <Gwiazda src={gwiazdka} alt="Gwiazdka" />
          <Gwiazda src={gwiazdka} alt="Gwiazdka" />
        </Opinie>
        <Podstawa id="dlaczego-my">ocena na podstawie opinii Google</Podstawa>
      </Opis>

      {isMobile ? (
        <>
          <ZdjecieMobile src={tlomobile} alt="Justyna i Paweł" />
        </>
      ) : (
        <></>
      )}
    </Sekcja>
  );
};

export default StronaTytulowa;
