import { MenuLink, Wrapper, Button, NewLink } from "./styled";
import Contact from "../../constants";

const MenuLinks = ({ handleLinkClick, scrollToUp }) => {
  return (
    <Wrapper>
      <NewLink
        to="/"
        onClick={() => {
          scrollToUp();
          handleLinkClick();
        }}
      >
        Home
      </NewLink>
      <MenuLink href="#kredyty" onClick={handleLinkClick}>
        Kredyty
      </MenuLink>
      <MenuLink href="#kontakt" onClick={handleLinkClick}>
        Kontakt
      </MenuLink>
      <MenuLink href={`tel:${Contact.PHONE}`} onClick={handleLinkClick}>
        📞 783 737 050
      </MenuLink>
      <Button href="#formularz">bezpłatna konsultacja</Button>
    </Wrapper>
  );
};

export default MenuLinks;
