import StronaTytulowa from "./StronaTytulowa";
import DlaczegoMy from "./DlaczegoMy";
import CoZyskasz from "./CoZyskasz";
import OfertaPrywatna from "./OfertaPrywatna";
import OfertaFirmowa from "./OfertaFirmowa";
import Opinie from "./Opinie";
import Formularz from "./Formularz";
import SpotkajmySie from "./SpotkajmySie";

const SerceFinansow = () => (
  <>
  <StronaTytulowa />
  <DlaczegoMy />
  <CoZyskasz />
  <OfertaPrywatna />
  <OfertaFirmowa />
  <Opinie />
  <Formularz />
  <SpotkajmySie />
  </>
);

export default SerceFinansow;
