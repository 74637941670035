import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    body {
        background: ${({ theme }) => theme.color.light};
        color: ${({ theme }) => theme.color.black};
        word-break: break-word;
        font-family: "Roboto", sans-serif;
    }

    h1 {
        font-size: 48px;
        font-weight: 900;

        @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
            font-size: 27px;
        }
    }

    h2 {
        font-size: 48px;
        font-weight: 900;

        @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
            font-size: 27px;
        }
    }

    h3 {
        font-size: 36px;
        font-weight: 900;

        @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
            font-size: 27px;
        }
    }

    h4 {
        font-size: 26px;
        line-height: 52px;
        font-weight: 700;
    }

    section {
        padding: 20px 50px;

        @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
            padding: 20px 8px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;

        @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }

`;