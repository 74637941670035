import Contact from "../../constants";
import media from "../../images/icon-fb.png";
import insta from "../../images/icon-ig.png";
import mail from "../../images/icon-mail.png";

export const footer = [
  {
    link: `${Contact.FACEBOOK}`,
    src: media,
    alt: `facebook`,
  },
  {
    link: `${Contact.INSTAGRAM}`,
    src: insta,
    alt: `instagram`,
  },
  {
    link: `mailto:${Contact.EMAIL}`,
    src: mail,
    alt: `e-mail`,
  },
];
