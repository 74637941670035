import styled from "styled-components";

export const Sekcja = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    align-items: center;
  }
`;

export const Opis = styled.div`
  max-width: 550px;
  min-width: 350px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const Elementy = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: -25px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    align-self: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PodpisJustyna = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
`;

export const PodpisPawel = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 200px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    grid-area: 3 / 1 / 4 / 2;
  }
`;

export const Justyna = styled.p`
  max-width: 200px;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.orange};
`;

export const WyksztalcenieJustyna = styled.p`
  font-size: 14px;
  line-height: 18px;
  width: 140px;
`;

export const Pawel = styled.p`
  text-align: right;
  margin-bottom: 0;
  max-width: 200px;
  border-bottom: 1px solid ${({ theme }) => theme.color.orange};
`;

export const WyksztalceniePawel = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  width: 140px;
  align-self: flex-end;
`;

export const ZdjeciePawel = styled.img`
  grid-area: 1 / 3 / 3 / 4;
  align-self: end;
  max-width: 200px;
  height: auto;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    max-width: 150px;
    grid-area: 2 / 2 / 4 / 2;
  }
`;

export const ZdjecieJustyna = styled.img`
  grid-area: 1 / 1 / 3 / 2;
  max-width: 200px;
  height: auto;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    max-width: 150px;
  }
`;

export const Paragraf = styled.p`
  font-size: 24px;
`;

export const Informacja = styled.div`
  height: 200px;
  background: ${({ theme }) => theme.color.orange};
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 50px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding: 20px 8px;
  }
`;

export const Naglowek = styled.h4`
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    margin-top: 0px;
  }
`;

export const Podnaglowek = styled.p`
  margin-top: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    margin-top: 0px;
  }
`;
