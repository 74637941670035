import {
  Sekcja,
  Glowna,
  Dane,
  Icon,
  Logo,
  Hyperlink,
  StyledLink,
  Container,
  Stopka,
  Linki,
  Tytul,
  Opis,
} from "./styled";
import logo from "../../images/serce-finansow-16.png";
import { footer } from "./footer";

const Kontakt = () => {
  const scrollToUp = () => window.scrollTo({ top: 0 });

  return (
    <Sekcja>
      <Glowna>
        <Dane>
          <div>
            <Tytul>Biuro</Tytul>
            <Opis>ul. Aleksandry Piłsudskiej 11 lok. U3 16-400 Suwałki</Opis>
          </div>
          <div>
            <Tytul>Numer telefonu</Tytul>
            <Opis>783 677 616 - Justyna</Opis>
            <Opis>783 737 050 - Paweł</Opis>
          </div>
          <div>
            <Tytul>Godziny otwarcia</Tytul>
            <Opis>pon - pt : 9:00 - 17:00</Opis>
          </div>
          <div>
            <Tytul>Adres e-mail</Tytul>
            <Opis>biuro@sercefinansow.pl</Opis>
          </div>
        </Dane>
        <Logo>
          <img src={logo} alt="logo SerceFinansow" width="300px" />
          <Container>
            {footer.map(({ link, src, alt }) => (
              <Hyperlink
                key={alt}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon src={src} alt={alt} width="40px" height="40px" />
              </Hyperlink>
            ))}
          </Container>
        </Logo>
      </Glowna>
      <Stopka>
        <p>© Copyright 2024 - Serce Finansów</p>
        <Linki>
          <StyledLink to="/polityka-prywatności" onClick={scrollToUp}>
            Polityka Prywatności
          </StyledLink>
          <p>|</p>
          <StyledLink to="/regulamin" onClick={scrollToUp}>
            Regulamin
          </StyledLink>
        </Linki>
      </Stopka>
    </Sekcja>
  );
};

export default Kontakt;
