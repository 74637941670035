import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Logo, Hamburger } from "./styled";
import { theme } from "../../theme";
import logo from "../../images/serce-finansow-logo.svg";
import hamburger from "../../images/hamburger.svg";
import MenuLinks from "./menuLinks";
import { Link } from "react-router-dom";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint.tabletMax });

  const scrollToUp = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <Navigation>
      <Link to="/" onClick={scrollToUp}>
        <Logo src={logo} alt="logo Serce Finansów" />
      </Link>

      {isMobile ? (
        <>
          <Hamburger src={hamburger} alt="menu" onClick={handleMenuToggle} />
          {isMenuOpen && (
            <MenuLinks
              handleLinkClick={handleLinkClick}
              scrollToUp={scrollToUp}
            />
          )}
        </>
      ) : (
        <MenuLinks handleLinkClick={handleLinkClick} scrollToUp={scrollToUp} />
      )}
    </Navigation>
  );
};

export default Menu;
