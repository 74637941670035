import kredyty from "../../../images/ofertapriv/1-Kredyty-hipoteczne.svg";
import pozyczki from "../../../images/ofertapriv/2-Pozyczki-hipoteczne.svg";
import refinansowanie from "../../../images/ofertapriv/3-Refinansowanie-kosztow.svg";
import gotowkowe from "../../../images/ofertapriv/4-Kredyty-gotowkowe.svg";
import konsolidacje from "../../../images/ofertapriv/5-Konsolidacje-zobowiazan.svg";
import audyt from "../../../images/ofertapriv/6-Audyt-kredytowy.svg";
import przeniesienie from "../../../images/ofertapriv/7-Przeniesienie-kredytu.svg";

export const ofertapriv = [
  {
    imgSrc: kredyty,
    tytul: `Kredyty hipoteczne`,
  },
  {
    imgSrc: pozyczki,
    tytul: `Pożyczki hipoteczne`,
  },
  {
    imgSrc: refinansowanie,
    tytul: `Refinansowanie kosztów
        zakupu nieruchomości`,
  },
  {
    imgSrc: gotowkowe,
    tytul: `Kredyty gotówkowe`,
  },
  {
    imgSrc: konsolidacje,
    tytul: `Konsolidacje zobowiązań`,
  },
  {
    imgSrc: audyt,
    tytul: `Audyt kredytowe`,
  },
  {
    imgSrc: przeniesienie,
    tytul: `Przeniesienie kredytu (refinansowanie)`,
  },
];
