import styled from "styled-components";
import tlo from "../../../images/zielone-tlo.jpg";

export const Sekcja = styled.section`
  text-align: center;
  background: center / cover no-repeat url(${tlo});
  color: ${({ theme }) => theme.color.white};
`;

export const Przycisk = styled.div`
  margin-top: -40px;
  margin-bottom: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled.a`
  cursor: pointer;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.color.orange};
  border: none;
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 15px 40px;
`;

export const Podpis = styled.p`
  font-size: 14px;
  text-align: right;
`;

export const Tytul = styled.h3`
  max-width: 800px;
  margin: 0 auto;
  font-weight: 700;
`;

export const Podtytul = styled.h4`
  color: ${({ theme }) => theme.color.orange};
  margin: 16px auto;
  line-height: 26px;
`;

export const Tekst = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const Oferta = styled.div`
  max-width: 1000px;
  margin: 30px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Oferujemy = styled.div`
  border: 2px solid ${({ theme }) => theme.color.orange};
  width: 200px;
  height: 48px;
  border-radius: 34px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Linia = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.orange};
  flex-grow: 1;
  height: 2px;
`;

export const Kontener = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 50px auto;
  gap: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;

    & > div:nth-last-child(1) {
      grid-column: 1 / -1;
      justify-self: center;
    }
  }
`;

export const Ikona = styled.img`
  width: 50px;
  height: 50px;
`;

export const Opis = styled.p`
  max-width: 200px;
  font-weight: bold;
  margin: 10px auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    max-width: 130px;
  }
`;
