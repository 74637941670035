import leasingaut from "../../../images/ofertafirma/1-Leasing-samochodowy.svg";
import leasingsprzetu from "../../../images/ofertafirma/2-Leasing-sprzetu-i-maszyn.svg";
import obrotowe from "../../../images/ofertafirma/3-Kredyty-obrotowe.svg";
import inwestycyjne from "../../../images/ofertafirma/4-Kredyty-inwestycyjne.svg";
import faktoring from "../../../images/ofertafirma/5-Faktoring.svg";

export const ofertafirm = [
  {
    imgSrc: leasingaut,
    tytul: `Leasing samochodowy`,
  },
  {
    imgSrc: leasingsprzetu,
    tytul: `Leasing sprzętu i maszyn`,
  },
  {
    imgSrc: obrotowe,
    tytul: `Kredyty obrotowe`,
  },
  {
    imgSrc: inwestycyjne,
    tytul: `Kredyty inwestycyjne`,
  },
  {
    imgSrc: faktoring,
    tytul: `Faktoring`,
  },
];
