export const zysk = [
  {
    numer: 1,
    tytul: `Indywidualne podejście`,
    artykul: `Każdy klient jest dla nas wyjątkowy. Twoje potrzeby i cele są naszym priorytetem,
        dlatego dostosowujemy nasze usługi do Twoich oczekiwań.`,
  },
  {
    numer: 2,
    tytul: `Profesjonalizm i doświadczenie`,
    artykul: `Nasz zespół składa się z doświadczonych ekspertów w dziedzinie finansów
        i rachunkowości. Otrzymujesz gwarancję profesjonalizmu i wysokiej jakości usług.`,
  },
  {
    numer: 3,
    tytul: `Kompleksowe wsparcie`,
    artykul: `Zapomnij o bieganiu po różnych instytucjach – u nas załatwisz wszystko szybko i bez stresu.
        Od wyboru najlepszej oferty kredytowej, przez pomoc w załatwianiu formalności,
        aż po finalizację transakcji – jesteśmy z Tobą na każdym kroku.`,
  },
  {
    numer: 4,
    tytul: `Najlepsze oferty na rynku`,
    artykul: `Współpracujemy z wieloma bankami i instytucjami finansowymi, dzięki czemu możemy
        zaproponować Ci najkorzystniejsze warunki kredytowe dostosowane do Twojej sytuacji finansowej.`,
  },
  {
    numer: 5,
    tytul: `Oszczędność czasu i pieniędzy`,
    artykul: `Dzięki naszym usługom zaoszczędzisz cenny czas i unikniesz zbędnych kosztów.
        Zajmiemy się wszystkimi formalnościami, a Ty będziesz mógł cieszyć się spokojem
        i pewnością, że wszystko jest pod kontrolą.`,
  },
];
