import {
  Sekcja,
  Opis,
  Elementy,
  PodpisJustyna,
  PodpisPawel,
  Justyna,
  Pawel,
  WyksztalcenieJustyna,
  WyksztalceniePawel,
  ZdjeciePawel,
  ZdjecieJustyna,
  Paragraf,
  Informacja,
  Naglowek,
  Podnaglowek,
} from "./styled";
import JustynaZdjecie from "../../../images/justyna-zdjecie.png";
import PawelZdjecie from "../../../images/pawel-zdjecie.png";

const DlaczegoMy = () => (
  <>
    <Sekcja>
      <Opis>
        <h2>Dlaczego my?</h2>
        <p>
          Zastanawiasz się,
          <strong> jak poradzić sobie ze wszystkimi formalnościami </strong> i
          kredytem na wymarzony dom czy mieszkanie?
        </p>
        <p>
          <strong>Przerasta Cię ilość banków i ich ofert? </strong> Nie wiesz od
          czego zacząć?
        </p>
        <p>
          W Sercu Finansów dostaniesz kompleksową pomoc <br />
          <strong> i wsparcie w każdym z tych obszarów. </strong>
        </p>
        <p>Zapomnij o bieganiu po różnych instytucjach.</p>
        <Paragraf>
          <strong>U nas załatwisz wszystko szybko i bez stresu.</strong>
        </Paragraf>
      </Opis>
      <Elementy>
        <ZdjecieJustyna src={JustynaZdjecie} alt="Justyna"/>
        <PodpisJustyna>
          <Justyna>
            <strong>
              Justyna <br /> Kowalewska
            </strong>
          </Justyna>
          <WyksztalcenieJustyna>
            Magister Finansów i Rachunkowości ze Szkoły Głównej Handlowej w
            Warszawie
          </WyksztalcenieJustyna>
        </PodpisJustyna>
        <PodpisPawel>
          <Pawel>
            <strong>
              Paweł <br /> Kowalewski
            </strong>
          </Pawel>
          <WyksztalceniePawel>
            Absolwent programu MBA w Wyższej Szkole Biznesu National Louis
            University
          </WyksztalceniePawel>
        </PodpisPawel>
        <ZdjeciePawel src={PawelZdjecie} alt="Paweł"/>
      </Elementy>
    </Sekcja>
    <Informacja>
      <div>
        <Naglowek>Ponad 7 lat</Naglowek>
        <Podnaglowek>doświadczenia w branży</Podnaglowek>
      </div>
      <div>
        <Naglowek>Ponad 1000</Naglowek>
        <Podnaglowek>zadowolonych klientów</Podnaglowek>
      </div>
      <div>
        <Naglowek>Aż 98%</Naglowek>
        <Podnaglowek>pozytywnynych decyzji</Podnaglowek>
      </div>
      <div>
        <Naglowek>Wszystkie banki</Naglowek>
        <Podnaglowek>w jednym miejscu</Podnaglowek>
      </div>
    </Informacja>
  </>
);

export default DlaczegoMy;
