export const theme = {
    color: {
        black: "#1d1d1b",
        light: "#fffcf5",
        white: "#ffffff",
        orange: "#ffa900",
        green: "#1c5d27",
    },
    breakpoint: {
        mobileMax: 767,
        tabletMax: 1023,
    },
};