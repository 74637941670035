export const klienci = [
  {
    autor: `Hubert M.`,
    tekst: `"Odpowiedni człowiek na odpowiednim stanowisku. Bardzo się cieszę, że trafiłem do Pana Pawła i jego żony. Zaangażowany zespół zasługujący na pochwalę. Będę polecał każdemu. Znajomość przepisów, obsługa klienta, wszystkie formalności dopilnowane, cierpliwość w tłumaczeniu nawet tak prostym osobom jak ja. Panie Pawle gratuluję przedsiębiorczości. Dziękuję. Pozdrawiam. Ocena max z możliwych."`,
  },
  {
    autor: `Ewa W.`,
    tekst: `"Z całego serca polecam Pana Pawła, dokładny,rzetelny, wyrozumiały i pomocny. Wszystko przygotowane na czas w tempie ekspresowym, współpraca z klientem na najwyższym poziomie. Jednym słowem odpowiedni człowiek na odpowiednim stanowisku,"przeprowadzi" przez proces kredytowy największych laików od początku do końca. Polecam!"`,
  },
  {
    autor: `Urszula W.`,
    tekst: `"Współpraca i kontakt na najwyższym poziomie. Pan Paweł jest świetnym specjalistą w swojej dziedzinie, do tego skrupulatny, konkretny, dobrze zorganizowany, potrafił nam wyjaśnić wszystko co było niezrozumiałe. W Sercu Finansów czuliśmy się jakbyśmy przychodzili do znajomych omówić bieżące sprawy 😊 pełen profesjonalizm i do tego miła atmosfera! Polecamy pana Pawła i panią Justynę!"`,
  },
  {
    autor: `Piotr A.`,
    tekst: `"Pełen profesjonalizm - tak mogę określić pracę Pana Pawła! Osoba, która bardzo dobrze zna się na tym co robi, posiada ogromną wiedzę,którą wykorzystuje przy uzyskiwaniu jak najlepszych warunków kredytowych. Zawsze pomocny, a wszelkie pytania i wątpliwości rozwiewa w mig! 😉 Z czystym sumieniem polecam usługi Pana Pawła!"`,
  },
  {
    autor: `Małgorzata W.`,
    tekst: `"Serdecznie polecam Pana Pawła, potrafi dokonać niemożliwego, trudne sprawy są mu niestraszne. Dzięki niemu udało się nam otrzymać kredyt i kupić wymarzone mieszkanie. Pełen profesjonalizm i rzetelność są gwarantowane podczas korzystania z jego usług. Jesteśmy bardzo zadowoleni i bardzo wdzięczni. Będziemy kontynuować współpracę przy kolejnych projektach. Pozdrawiamy serdecznie."`,
  },
  {
    autor: `Olga P.`,
    tekst: `"Serce Finansów wyróżnia pełen profesjonalizm oraz efektywność. Wszystkie sprawy ustalane, dokładnie wyjaśniane oraz załatwiane na bieżąco. Każda sugestia była brana pod uwagę, dopracowany każdy szczegół. Pawle, DZIĘKUJEMY za osiągnięcie celu oraz za super współpracę, mega cierpliwość i pomoc o każdej porze podczas tych wszystkich etapów.
    Szczerze polecam każdemu!! 😊"`,
  },
];
