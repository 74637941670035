import { klienci } from "./klienci";
import {
  Sekcja,
  Kontener,
  Strzalka,
  Karta,
  Autor,
  Gwiazda,
  Opinia,
  DotsContainer,
  Dot,
  Tytul,
  Ikona,
  Wstep,
} from "./styled";
import { useState, useEffect } from "react";
import ikona from "../../../images/icon-google.png";
import gwiazdka from "../../../images/gwiazdka.png";
import { theme } from "../../../theme";

const Opinie = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opinionsPerPage, setOpinionsPerPage] = useState(3);

  useEffect(() => {
    const updateOpinionsPerPage = () => {
      if (window.innerWidth < theme.breakpoint.tabletMax) {
        setOpinionsPerPage(1);
      } else {
        setOpinionsPerPage(3);
      }
    };

    window.addEventListener("resize", updateOpinionsPerPage);
    updateOpinionsPerPage();
    return () => window.removeEventListener("resize", updateOpinionsPerPage);
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % klienci.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? klienci.length - 1 : prevIndex - 1
    );
  };

  const getDisplayedOpinions = () => {
    if (opinionsPerPage === 1) {
      return [klienci[currentIndex]];
    }

    const prevIndex = (currentIndex - 1 + klienci.length) % klienci.length;
    const nextIndex = (currentIndex + 1) % klienci.length;

    return [klienci[prevIndex], klienci[currentIndex], klienci[nextIndex]];
  };

  const displayedOpinions = getDisplayedOpinions();

  return (
    <Sekcja>
      <h3>Co mówią o nas inni?</h3>
      <Kontener>
        <Strzalka direction="left" onClick={goToPrev}>
          ◀
        </Strzalka>
        {displayedOpinions.map((opinia, index) => (
          <Karta key={index}>
            <Tytul>
              <Wstep>
                <Ikona src={ikona} alt="znak Google" />
                <Autor>{opinia.autor}</Autor>
              </Wstep>
              <div>
                <Gwiazda src={gwiazdka} alt="Gwiazdka" />
                <Gwiazda src={gwiazdka} alt="Gwiazdka" />
                <Gwiazda src={gwiazdka} alt="Gwiazdka" />
                <Gwiazda src={gwiazdka} alt="Gwiazdka" />
                <Gwiazda src={gwiazdka} alt="Gwiazdka" />
              </div>
            </Tytul>
            <Opinia>{opinia.tekst}</Opinia>
          </Karta>
        ))}
        <Strzalka direction="right" onClick={goToNext}>
          ▶
        </Strzalka>
      </Kontener>
      <DotsContainer id="formularz">
        {klienci.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </DotsContainer>
    </Sekcja>
  );
};

export default Opinie;
