import styled from "styled-components";
import { Link } from "react-router-dom";

export const Navigation = styled.nav`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  position: fixed;
  width: 100%;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    padding: 10px;
  }
`;

export const Logo = styled.img`
  height: 70px;
`;

export const Wrapper = styled.aside`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    gap: 0;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 252, 245, 0.9);
    border-bottom: 1px solid ${({ theme }) => theme.color.grey};
  }
`;

export const MenuLink = styled.a`
  margin: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};

  &:hover {
    color: ${({ theme }) => theme.color.orange};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    display: block;
  }
`;

export const NewLink = styled(Link)`
  margin: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};

  &:hover {
    color: ${({ theme }) => theme.color.orange};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    display: block;
  }
`;

export const Hamburger = styled.img`
  width: 30px;
`;

export const Button = styled.a`
  cursor: pointer;

  border-radius: 34px;
  background-color: ${({ theme }) => theme.color.green};
  border: none;
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 15px 40px;
`;
