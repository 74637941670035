import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivacyPolitycy from "./features/PrivacyPolitycy";
import SerceFinansow from "./features/SerceFinansow";
import Kontakt from "./common/Kontakt";
import Menu from "./common/Menu";
import Regulamin from "./features/Regulamin";

function App() {
  return (
    <HashRouter>
      <Menu />

      <Switch>
        <Route path="/polityka-prywatności">
          <PrivacyPolitycy />
        </Route>
        <Route path="/regulamin">
          <Regulamin />
        </Route>
        <Route path="/">
          <SerceFinansow />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>

      <Kontakt />
    </HashRouter>
  );
}

export default App;
