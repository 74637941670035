import styled from "styled-components";

export const Wrapper = styled.section`
    padding: 40px 20px;
`;

export const Paragraph = styled.div`
    text-align: left;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 1.5;
`;

export const Title = styled.h2`
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 70px;
`;